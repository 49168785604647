/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container, NavbarBrand } from "reactstrap";
import logo from "../../../src/assets/img/notarias/logo27.png";
import { Link } from "react-router-dom";
// core components+

function FooterBlack() {
  return (
    <>
      <footer className="footer" data-background-color="black">
        <Container style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div className="copyright float-left">
            <a href="/TerminosCondiciones">
              <h6 className="info-title textot-ref" style={{ textAlign: 'left', cursor: "pointer" }}>Términos y condiciones</h6>
            </a>
            <a href="/PoliticasDatosPersonales">
              <h6 className="info-title textot-ref" style={{ textAlign: 'left', cursor: "pointer" }}>Política de Tratamiento de Datos Personales</h6>
            </a>
            <a href="/PoliticasDerechosAutor">
              <h6 className="info-title textot-ref" style={{ textAlign: 'left', cursor: "pointer" }}>Politícas de derechos de autor</h6>
            </a>
            <a href="/CertificadoAccesibilidad">
              <h6 className="info-title textot" style={{ textAlign: 'left', cursor: "pointer" }}>Certificado de Accesibilidad</h6>
            </a>
            <a href="/PoliticasPrivacidadWeb">
              <h6 className="info-title textot" style={{ textAlign: 'left', cursor: "pointer" }}>Políticas de Privacidad web</h6>
            </a>
            <a href="/MapaSitio">
              <h6 className="info-title textot" style={{ textAlign: 'left', cursor: "pointer" }}>Mapa del Sitio</h6>
            </a>
            <a href="/#contacto">
              <h6 className="info-title textot" style={{ textAlign: 'left', cursor: "pointer" }}>Canal de Atencion al Publico</h6>
            </a>
            <h6 className="info-title textot" style={{ textAlign: 'left', cursor: "pointer" }}>Teléfono: (601)7438826</h6>
          </div>
          <div className="copyright" id="copyright"  >
            <NavbarBrand to="/" tag={Link} id="navbar-brand" style={{ alignItems: 'center' }}>
              <img style={{ backgroundColor: 'white', borderRadius: "15px" }} src={logo} alt="Logo de la notaria 27 de bogota" width="130px"></img>
            </NavbarBrand>

          </div>

        </Container>
        <div style={{ backgroundColor: '#474747', width: '100%', height: '40px', display: 'flex', alignItems: 'center', paddingLeft: '30px', justifyContent: 'left' }}>
          <a href="" target="_blank" style={{ textDecorationLine: "none" }} rel="noreferrer">
            <img
              alt="Logo de Instagram"
              src={require("assets/img/notarias/instagram.jpg")} style={{ height: '40px', borderRadius: "15px" }}
            ></img>
          </a>
          <a href="" target="_blank" style={{ textDecorationLine: "none" }} rel="noreferrer">
            <img
              alt="Logo de Twitter"
              src={require("assets/img/notarias/twitter.png")} style={{ height: '40px', borderRadius: "15px" }}
            ></img>
          </a>
          <a href="" target="_blank" style={{ textDecorationLine: "none"  }} rel="noreferrer">
            <img
              alt="Logo de Facebook"
              src={require("assets/img/notarias/facebook.png")} style={{ height: '40px', borderRadius: "15px" }}
            ></img>
          </a>
          © {new Date().getFullYear()}, Designed by{'\u00A0'}
          <a
            href="https://www.sinfony.com.co/sinfony/"
            target="_blank"
            rel="noreferrer"
          >
            SINFONY Todos los derechos reservados.
          </a>
        </div>
      </footer>
    </>
  );
}

export default FooterBlack;
